const TeamData = [
  {
    img: "/Developers/shreyash.jpg",
    name: "Shreyash Khandagle",
    Profession: "WebMaster",
    git: "https://github.com/Shreyashk-07",
    linkdin: "https://www.linkedin.com/in/shreyash-khandagale-5824a5227/",
    instagram: "https://www.instagram.com/shreyash_sk_07",
  },
  {
    img: "/Developers/yash.jpg",
    name: "Yash Waghmare",
    Profession: "WebMaster",
    git: "https://github.com/Yash-Waghmare",
    linkdin: "https://www.linkedin.com/in/yash-waghmare-7b8b51201/",
    instagram: "https://instagram.com/yash_7714?igshid=ZDdkNTZiNTM=",
  },
  {
    img: "/Developers/Suraj.jpg",
    name: "Suraj Tade",
    Profession: "WebMaster",
    git: "https://github.com/surajTade/",
    linkdin: "https://www.linkedin.com/in/suraj-tade",
    instagram: " https://instagram.com/suraj__tade?igshid=ZDdkNTZiNTM=",
  },
  {
    img: "/Developers/sarthak2.jpeg",
    name: "Sarthak Joshi",
    Profession: "Developer",
    git: "https://github.com/sarthak-subhash-joshi",
    linkdin: "https://www.linkedin.com/in/sarthak-joshi-6112b7231/",
    instagram: "https://www.instagram.com/sarthak_joshi_3011/",
  },
  {
    img: "/Developers/girishnew.jpeg",
    name: "Girish Bhosale",
    Profession: "Developer",
    git: "https://github.com/Girish345",
    linkdin: "https://www.linkedin.com/in/girish-bhosale-876b2121b/",
    instagram: "https://www.instagram.com/__girish__39/",
  },
  {
    img: "/Developers/Atharva.jpg",
    name: "Atharva Pande",
    Profession: "GDSC Web dev lead",
    git: "https://github.com/atharvapande45",
    linkdin: "https://www.linkedin.com/in/atharva-pande-855341244",
    instagram: "https://instagram.com/atharvapande45?igshid=ZDdkNTZiNTM=",
  },
  {
    img: "/Developers/aditya.jpg",
    name: "Aditya Lad",
    Profession: "Jr. WebMaster",
    git: "https://github.com/Adityalad-25",
    linkdin: "https://www.linkedin.com/in/aditya-lad-609586226",
    instagram: "https://instagram.com/_adi_ty_a?igshid=ZDdkNTZiNTM=",
  },
  {
    img: "/Developers/sejal.jpg",
    name: "Sejal Rahane",
    Profession: "Jr. WebMaster",
    git: "https://github.com/sejalrahane",
    linkdin: "https://www.linkedin.com/in/sejal-rahane-094447193",
    instagram: "https://instagram.com/sejal_rahane_?igshid=MTA2NDdkNWE=",
  },
  {
    img: "/Developers/Chetan.jpg",
    name: "Chetan Padhen",
    Profession: "Jr. WebMaster",
    git: " https://github.com/Chetax",
    linkdin: "https://www.linkedin.com/in/chetan-padhen-501416222/",
    instagram: "",
  },
  {
    img: "/Developers/mrudul.jpg",
    name: "Mrudul Ahirrao",
    Profession: "Jr. WebMaster",
    git: "https://github.com/MrudulAhirrao",
    linkdin: "https://in.linkedin.com/in/mrudul-ahirrao-133088216",
    instagram: "https://www.instagram.com/mrudul.ahirrao/",
  },
  {
    img: "/Developers/pranav.jpeg",
    name: "Pranav Kulkarni",
    Profession: "Jr. WebMaster",
    git: "https://www.github.com/pranavkulkarni2905",
    linkdin: "https://www.linkedin.com/in/pranavk2905",
    instagram: "https://instagram.com/i_am_pranavkulkarni?igshid=ZDdkNTZiNTM=",
  },
  {
    img: "/Developers/shraddha.jpg",
    name: "Shradha Yeole",
    Profession: "Jr. WebMaster",
    git: "https://github.com/Shraddha16092002",
    linkdin: "https://www.linkedin.com/in/shraddha-yeole-8519161b4/",
    instagram: "https://instagram.com/shradha_1602?igshid=ZDdkNTZiNTM=",
  },
  {
    img: "/Developers/Tarun.jpeg",
    name: "Tarun Rathod",
    Profession: "Jr. WebMaster",
    git: "https://github.com/tarunnnrathoddd",
    linkdin: "https://www.linkedin.com/in/tarun-rathod-aa7ab7223",
    instagram: "https://www.instagram.com/_.tarunnn.rathoddd._",
  },
  {
    img: "/Developers/Kanak.jpg",
    name: "Kanak Agrawal",
    Profession: "Jr. WebMaster",
    git: "https://github.com/kanak8403",
    linkdin: "https://www.linkedin.com/in/kanak-agrawal-78791a259/",
    instagram: "https://www.instagram.com/kanakagrawal08/. ",
  },

  {
    img: "/Developers/Sahil_S.jpg",
    name: "Sahil Shinde",
    Profession: "Vice-President",
    git: "",
    linkdin: "https://www.linkedin.com/in/thesahilshinde",
    instagram: "https://www.instagram.com/thesahilshinde/",
  },

  {
    img: "/Developers/Om Bidve.jpeg",
    name: "Om Bidve",
    Profession: "GDSC LEAD, CESA Vice President",
    git: "https://github.com/ombidve",
    linkdin: "https://www.linkedin.com/in/om-bidve-aa436a1ba/",
    instagram: "https://www.instagram.com/om__bidve/",
  },
  {
    img: "/Developers/aray_kaslikar.jpg",
    name: "Arya Kaslikar",
    Profession: "OWASP Lead",
    git: "https://github.com/AryaK-31",
    linkdin: "https://www.linkedin.com/in/arya-kaslikar-bb6957268/",
    instagram: "https://instagram.com/monsieur_ak31?igshid=ZDdkNTZiNTM=",
  },
  {
    img: "/Developers/bhagyesha.jpg",
    name: "Bhagyesha Patil ",
    Profession: "ACM-W President ",
    git: "http://github.com/Bhagyesha-P",
    linkdin: "https://www.linkedin.com/in/bhagyesha-patil-b78a88236",
    instagram: "http://instagram.com/bhagyesha_patil_28",
  },

  {
    img: "/Developers/Kartik_chaudhari.jpg",
    name: "Kartik Chaudhari",
    Profession: "Secretary",
    git: "https://github.com/Kartik1646",
    linkdin: "https://www.linkedin.com/in/kartik-chaudhari-39a872209",
    instagram: "https://www.instagram.com/_kartik.56",
  },
  {
    img: "/Developers/vaishnavi.jpeg",
    name: "Vaishnavi Jadhav",
    Profession: "Marketing OWASP",
    git: "",
    linkdin: "https://www.linkedin.com/in/vaishnavi-jadhav-3b6abb250/",
    instagram: "https://instagram.com/vaishnavi_jadhav._?igshid=YmMyMTA2M2Y=",
  },
  {
    img: "/Developers/Bhumika.jpg",
    name: "Bhumika Patil",
    Profession: "WebMaster ACM-W",
    git: "http://github.com/Bhumi9403",
    linkdin: "http://linkedin.com/in/bhumika-patil-77a8a5220/",
    instagram: "http://instagram.com/_bhumikapatil_2003/",
  },

  {
    img: "/Developers/yash_patel.jpeg",
    name: "Yash Patel",
    Profession: "Marketing OWASP",
    git: "https://github.com/Yaaashh",
    linkdin: "https://www.linkedin.com/in/yash-patel-80615b20a",
    instagram: "https://instagram.com/__yaaashh__?igshid=ZDdkNTZiNTM=",
  },
  {
    img: "/Developers/sarthak_zambre.JPG",
    name: "Sarthak Zambre",
    Profession: "Treasurer",
    git: "",
    linkdin: "https://www.linkedin.com/in/sarthakzambre",
    instagram: "https://instagram.com/sarthakz_9?igshid=YmMyMTA2M2Y=",
  },

  {
    img: "/Developers/mukgdha.jpg",
    name: "Mugdha Kshirsagar",
    Profession: "HSC Head",
    git: "https://github.com/mugdhaK21",
    linkdin: "https://www.linkedin.com/in/mugdha-kshirsagar-6400a31ba/",
    instagram: "https://instagram.com/mk_mugdha_21?igshid=ZDdkNTZiNTM=",
  },

  {
    img: "/Developers/Aniket.jpg",
    name: "Aniket Choudhar",
    Profession: "Sports Cell",
    git: "https://github.com/AniketC007",
    linkdin: "https://www.linkedin.com/in/aniket-choudhar-4bb813226",
    instagram: "https://www.instagram.com/aniket___0007/",
  },
  {
    img: "/Developers/khush.jpg",
    name: "Khush Kothari",
    Profession: "Marketing Head",
    git: "https://github.com/khussh3105",
    git: "https://github.com/khussh3105",
    linkdin: "https://www.linkedin.com/in/khush-kothari-6991b7213",
    instagram: "https://instagram.com/khushkothari31?igshid=ZDdkNTZiNTM=",
  },

  {
    img: "/Developers/ankita.jpg",
    name: "Ankita Nandy",
    Profession: "Social Media",
    git: "https://github.com/ankitanandy2608",
    linkdin: "https://www.linkedin.com/in/ankita-nandy-a3b79b236/",
    instagram: "https://www.instagram.com/ankita_nandy/",
  },
  {
    img: "/Developers/shrushti.JPG",
    name: "Shrushti Khirwadkar ",
    Profession: "HSC head",
    git: "https://github.com/shrushti-02",
    linkdin: "https://www.linkedin.com/in/shrushti-k-29726520a",
    instagram: "https://instagram.com/kshrushti_?igshid=ZDdkNTZiNTM=",
  },

  {
    img: "/Developers/Vishakha.jpeg",
    name: "Vishakha Deshpande ",
    Profession: "Jr. Secretary ",
    git: "https://github.com/VishhAAkha",
    linkdin: "https://www.linkedin.com/in/vishakha-deshpande-152a5b230",
    instagram: "https://instagram.com/vishhaakhaa?igshid=ZDdkNTZiNTM=",
  },
  {
    img: "/Developers/mahesh.JPG",
    name: "Mahesh Yarra",
    Profession: "Marketing Head",
    git: "https://github.com/Mahesh-Yarra",
    linkdin: "https://www.linkedin.com/in/mahesh-yarra-997963214/",
    instagram: "https://www.instagram.com/mahes_._hhh/",
  },
  {
    img: "/Developers/radhika.jpeg",
    name: "Radhika Gaikwad ",
    Profession: "Social Media head ",
    git: "https://github.com/radsss16",
    linkdin: "https://www.linkedin.com/in/radhika-gaikwad-0372a5202",
    instagram: "https://instagram.com/radhikaaa_g?igshid=YmMyMTA2M2Y=",
  },

  {
    img: "/Developers/tanmay.jpg",
    name: "Tanmay Jain ",
    Profession: "Photography Cell Head",
    git: "",
    linkdin: "https://www.linkedin.com/in/tanmay-jain-3a8243223",
    instagram: "https://instagram.com/skywalker_.15?igshid=ZDdkNTZiNTM=",
  },

  {
    img: "/Developers/Shyam.jpg",
    name: "Shyam Tiwari",
    Profession: "Photography Cell Head",
    git: "https://github.com/shyam-1024",
    linkdin: "https://www.linkedin.com/in/shyam-sundar-tiwari-780869208/",
    instagram: "https://www.instagram.com/everythingshyam/",
  },

  {
    img: "/Developers/Saish_K.jpg",
    name: "Saish Kothawade",
    Profession: "Co-Treasurer",
    git: "https://github.com/saishk237",
    linkdin: "https://www.linkedin.com/in/saish-kothawade-173609208",
    instagram: "https://www.instagram.com/saishk237/",
  },

  {
    img: "/Developers/Maithili_S.jpg",
    name: "Maithili Sude",
    Profession: "ACMW Treasurer ",
    git: "https://github.com/Maithiligithub19",
    linkdin: "https://www.linkedin.com/in/maithili-sude-92b60023a",
    instagram: "https://instagram.com/mahi19_07?igshid=MGU3ZTQzNzY=",
  },

  {
    img: "/Developers/Ruchi_T.png",
    name: "Ruchi Thakkar",
    Profession: "Marketing & Sponsorship Head",
    git: "https://github.com/ruchiee27",
    linkdin: "https://www.linkedin.com/in/ruchi-thakkar-a43799209/",
    instagram: "https://instagram.com/_ruchi27?igshid=YmMyMTA2M2Y=",
  },

  {
    img: "/Developers/Sanjay_C.jpg",
    name: "Sanjay Chilveri",
    Profession: "Art Circle Head",
    git: "https://github.com/Sanjaychilveri",
    linkdin: "https://www.linkedin.com/in/sanjay-chilveri-b118971bb/",
    instagram: "https://instagram.com/sanjaygopalchilveri?igshid=ZDdkNTZiNTM=",
  },
  {
    img: "/Developers/Ayush_S.jpg",
    name: "Ayush Sarode ",
    Profession: "Design Head",
    git: "",
    linkdin: "https://www.linkedin.com/in/ayush-sarode-b4993a215",
    instagram: "https://instagram.com/ayush_sarode_183?igshid=ZDdkNTZiNTM=",
  },

  {
    img: "/Developers/prathamesh.jpeg",
    name: "Prathamesh Bachhav",
    Profession: "SY Management head",
    git: "http://github.com/itspratham02",
    linkdin: "http://linkedin.com/in/prathamesh-bachhav-282b11229",
    instagram: "https://instagram.com/itspratham_02?igshid=YmMyMTA2M2Y=",
  },

  {
    img: "/Developers/mahima.jpg",
    name: "Mahima Nair",
    Profession: "SY Management Head",
    git: "https://github.com/mahima-nair",
    linkdin: "https://www.linkedin.com/in/mahima-nair-8995b823b/",
    instagram: "",
  },

  {
    img: "/Developers/sahil_bomble.JPG",
    name: "Sahil Bomble",
    Profession: "Security Team",
    git: "https://github.com/Justsah1l",
    linkdin: "https://www.linkedin.com/in/sahil-bomble-0432a01b9",
    instagram: "https://www.instagram.com/justsahil._/",
  },

  {
    img: "/Developers/Sanika.jpeg",
    name: "Sanika Mahabaleshwarkar",
    Profession: "SY Design Team",
    git: "https://github.com/mkarsanika",
    linkdin: "https://www.linkedin.com/in/sanika-mahabaleshwarkar-22051823b/",
    instagram: "https://www.instagram.com/sanika1072/",
  },

  {
    img: "/Developers/vallabh.png",
    name: "Vallabh Kulkarni",
    Profession: "Social Media",
    git: "https://github.com/tastycoderop",
    linkdin: "https://www.linkedin.com/in/vallabh-kulkarni984857/",
    instagram: "https://www.instagram.com/itsvallabh/",
  },
  {
    img: "/Developers/sahil.jpg",
    name: "Sahil Dhanwani",
    Profession: "Event Co-ordinator",
    git: "https://github.com/SahilDhanwani",
    linkdin: "https://www.linkedin.com/in/sahil-dhanwani-37825822b",
    instagram: "https://instagram.com/sahil_dhanwani3260?igshid=ZDdkNTZiNTM=",
  },

  {
    img: "/Developers/Tirthesh.jpeg",
    name: "Tirthesh S Patange",
    Profession: "Photography Cell",
    git: "https://github.com/tiirthesh",
    linkdin: "https://www.linkedin.com/in/tirthesh121",
    instagram: "https://www.instagram.com/tiirthesh",
  },

  {
    img: "/Developers/Vishakha.jpeg",
    name: "Vishakha Deshpande ",
    Profession: "Jr. Secretary ",
    git: "https://github.com/VishhAAkha",
    linkdin: "https://www.linkedin.com/in/vishakha-deshpande-152a5b230",
    instagram: "https://instagram.com/vishhaakhaa?igshid=ZDdkNTZiNTM=",
  },

  {
    img: "/Developers/aditya_agre.jpeg",
    name: "Aditya Agre",
    Profession: "Management head GDSC",
    git: "https://github.com/adityaagre",
    linkdin: "https://www.linkedin.com/in/aditya-agre-816558220/",
    instagram: "https://www.instagram.com/_adityaagre_/",
  },

  {
    img: "/Developers/saie.jpg",
    name: "Saie Mukane ",
    Profession: "PR executive ",
    git: "",
    linkdin: "https://www.linkedin.com/in/saie-mukane-242562229",
    instagram: "https://instagram.com/_saieee?igshid=MGNiNDI5ZTU=",
  },

  {
    img: "/Developers/Vidyesh.jpg",
    name: "Vidyesh Gajanan Dhande",
    Profession: "NSS Cell",
    git: "http://github.com/viddhande",
    linkdin: "https://www.linkedin.com/in/vidyesh-dhande-181613229",
    instagram: "https://instagram.com/vid_2772?igshid=YmMyMTA2M2Y=",
  },

  {
    img: "/Developers/prajakta_m.jpg",
    name: "Prajakta Maratkar",
    Profession: "Social media ",
    git: "https://github.com/Prajakta293",
    linkdin: "https://www.linkedin.com/in/prajakta-maratkar-3682a4231",
    instagram: "https://instagram.com/praj_akta293?igshid=ZDdkNTZiNTM=",
  },
  {
    img: "/Developers/Onkar.jpg",
    name: "Onkar Dokhe",
    Profession: "Application Developer",
    git: "https://github.com/onkar-dokhe",
    linkdin: "https://linkedin.com/in/onkar-dokhe",
    instagram: "https://instagram.com/onkar_dokhe",
  },
  {
    img: "/Developers/Siddhi_B.jpg",
    name: "Siddhi Bajpai",
    Profession: "Art Circle Cell ",
    git: "https://github.com/siddhi-bajpai",
    linkdin: "https://www.linkedin.com/in/siddhi-bajpai-4a20bb22a/",
    instagram: "https://www.instagram.com/siddhibajpai12/",
  },

  {
    img: "/Developers/Vaibhavi_P.jpg",
    name: "Vaibhavi Pawar",
    Profession: "Higher Studies Cell coordinator ",
    git: "https://github.com/vaibhaviP12",
    linkdin: "https://www.linkedin.com/in/vaibhavi-pawar-404906259",
    instagram:
      "https://www.instagram.com/invites/contact/?i=92zwdwrkh375&utm_content=pqzt0q0",
  },

  {
    img: "/Developers/Kedar_k.jpg",
    name: "Kedar Kulkarni ",
    Profession: "Higher Studies Cell ",
    git: "https://github.com/KedarK17",
    linkdin: "https://www.linkedin.com/in/kedar-kulkarni-237a42217",
    instagram: "https://instagram.com/kedar_715?igshid=OTJhZDVkZWE=",
  },

  {
    img: "/Developers/Jayati_W.jpg",
    name: "Jayati Wajire",
    Profession: "Design Team",
    git: "https://github.com/Jayati-Wajire",
    linkdin: "https://www.linkedin.com/in/jayati-wajire-27056423a/",
    instagram: "https://www.instagram.com/jayatiwajire/",
  },

  {
    img: "/Developers/Mahesh_D.jpeg",
    name: "Mahesh Dargad",
    Profession: "Marketing and Sponsorship",
    git: "https://github.com/MrDargad",
    linkdin: "https://linkedin.com/in/maheshdargad/",
    instagram: "https://instagram.com/mahesh_dargad/",
  },
  {
    img: "/Developers/Sejal_K.jpg",
    name: "Sejal Kotkar ",
    Profession: "Art Circle ",
    git: "",
    linkdin: "https://www.linkedin.com/in/sejal-kotkar-6ab916231",
    instagram: "https://instagram.com/sejal_kotkar?igshid=ZDdkNTZiNTM=",
  },
  {
    img: "/Developers/Prajakta_T.jpg",
    name: "Prajakta Tambe ",
    Profession: "NSS cell",
    git: "https://github.com/Prajakta514",
    linkdin: "https://www.linkedin.com/in/prajakta-tambe-56672424b",
    instagram: "",
  },
  {
    img: "/Developers/Aditi_D.jpeg",
    name: "Aditi Dabhade",
    Profession: "Marketing and Sponsorship ",
    git: "https://github.com/dabhadeaditi",
    linkdin: "https://www.linkedin.com/in/aditi-dabhade-b5039326b",
    instagram: "https://instagram.com/aaditi_943?igshid=YmMyMTA2M2Y=",
  },
  {
    img: "/Developers/Prajwal_L.jpg",
    name: "Prajwal Lonari",
    Profession: "ISR CELL ",
    git: "https://github.com/PRAJWAL3628",
    linkdin: "https://www.linkedin.com/in/prajwal-lonari-55932222a",
    instagram: "https://instagram.com/prajwal_lonari21903?igshid=ZDdkNTZiNTM=",
  },
  {
    img: "/Developers/Pranjali_D.jpg",
    name: "Pranjali Deshpande",
    Profession: "Junior Secretary",
    git: "https://github.com/pranjali1312",
    linkdin: "https://www.linkedin.com/in/pranjali-deshpande-ba5995229",
    instagram: "",
  },
  {
    img: "/Developers/Satyam_M.jpg",
    name: "Satyam Mirgane ",
    Profession: "SY Coordinator ",
    git: "https://github.com/satyam01mirgane",
    linkdin: "https://www.linkedin.com/in/satyam-mirgane-747009238",
    instagram: "https://instagram.com/shots_of_life01?igshid=ZDdkNTZiNTM=",
  },
  {
    img: "/Developers/Shruti_B.jpg",
    name: "Shruti Baravkar",
    Profession: "NSS Cell",
    git: "https://github.com/Shrut0025",
    linkdin: "https://www.linkedin.com/in/shruti-baravkar-348a63259",
    instagram: "https://instagram.com/shruti001243?igshid=ZDdkNTZiNTM=",
  },
  {
    img: "/Developers/Prajwal_M.jpg",
    name: "Prajwal More",
    Profession: "SY Design Team  ",
    git: "https://github.com/PrajwalMore26",
    linkdin: "https://www.linkedin.com/in/prajwal-more-a097211a3",
    instagram: "https://instagram.com/prajwal._.more_?igshid=ZDdkNTZiNTM=",
  },

  {
    img: "/Developers/Prathmesh_K.jpeg",
    name: "Prathamesh Kshirsagar ",
    Profession: "OWASP Design Executive ",
    git: "https://github.com/Prathamesh06203",
    linkdin: "https://www.linkedin.com/in/prathamesh-kshirsagar-65812a217/",
    instagram: "https://instagram.com/kshirsagar_prathmesh_0602/",
  },
];

export default TeamData;
